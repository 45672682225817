
export const HOMEBANNERIMG = require("../assets/homeBannerImg.png");
export const HOMERECTANGLE = require("../assets/HomeRectangleImg.png");
export const Star = require("../assets/Star.png");
export const Star5 = require("../assets/Star 5.png");
export const ADMINLOGO = require("../assets/adminLogo.png");









