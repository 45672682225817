// App.js - WEB
import React, { Component } from "react";
import BBHomeScreen from '../../../../components/src/HomeScreen';
import HomeScreen from "../../../dashboard/src/Home.web";
/** WebRoutes file **/
// @ts-ignore
import WebRoutes from "./webRoutes";

//css
// import "bootstrap/dist/css/bootstrap.min.css";
import 'react-loading-skeleton/dist/skeleton.css'
import "./App.css";

if (!BBHomeScreen.instance && !HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: "HomeScreen"
  };
  const homeScreen = new HomeScreen(defaultProps);
}
class App extends Component {
  render() {
    return (
        <WebRoutes />
    )
  }
}

export default App;
