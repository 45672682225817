//@ts-nocheck
import React, { Component, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

/** Routes List start */
const HomeWeb = lazy(() => import('../../../dashboard/src/Home.web'));
const MyLearningDashboard = lazy(() => import('../../../dashboard/src/HomeElearningDashboard/MyLearningDashboard.web'));
const CourseListing = lazy(() => import('../../../dashboard/src/HomeElearningDashboard/CourseListing.web'));
const EmailAccountLogin = lazy(() => import('../../../email-account-registration/src/EmailAccountLogin.web'));
//const CourseContentPdf = lazy(() => import('../../../CourseContent/src/CourseContentPdf.web'));
const CourseContent = lazy(() => import('../../../CourseContent/src/CourseContent.web'));
const CourseQuiz = lazy(() => import('../../../CourseContent/src/CourseQuiz.web'));
const CourseText = lazy(() => import('../../../CourseContent/src/CourseText.web'));
const CourseAssignment = lazy(() => import('../../../CourseContent/src/CourseAssignment.web'));
/** Routes List End */


/** Private Routes start */
import PrivateRoute from "./PrivateRoute.web";
import ProtectedRoute from "./ProtectedRoute.web";
import Loader from "../../../../components/src/Loader.web";
import HeaderNav from "../../../studio-store-elearning-components/src/AppWebHeader/HeaderNav.web";
import FooterWeb from "../../../studio-store-elearning-components/src/AppWebFooter/Footer.web";
// import AdminLayout from '../../../dashboard/src/AdminLayout'
// import WishlistWeb from "../../../user-profile-basic/src/Wishlist.web";
/** Private Roues End */
class WebRoutes extends Component {
  render() {
    // const Appthem = JSON.parse(localStorage.getItem("appThemData"));
    // const StripePubKey = Appthem?.PaymentKeys?.stripe?.stripe_pub_key
    //   ? Appthem?.PaymentKeys?.stripe?.stripe_pub_key
    //   : "";
    // const stripePromise = loadStripe(StripePubKey);
    return (

      <div>
        {/* <Elements stripe={stripePromise}> */}

        <HeaderNav />
        <Suspense fallback={<Loader loading />}>
          <Switch>
            <Route
              path="/"
              exact
              render={props => <HomeWeb {...props} />}
            />
            <Route
              path="/login"
              exact
              render={props => <EmailAccountLogin {...props} />}
            />
            <Route
              path="/home-dashboard"
              exact
              render={props => <MyLearningDashboard {...props} />}
            />
            <Route
              path="/course-details"
              exact
              render={props => <CourseContent {...props} />}
            />
            <Route
              path="/course-quiz"
              exact
              render={props => <CourseQuiz {...props} />}
            />
            <Route
              path="/course-text"
              exact
              render={props => <CourseText {...props} />}
            />

            <Route
              path="/course-assignment"
              exact
              render={props => <CourseAssignment {...props} />}
            />
            <Route
              path="/"
              exact
              render={props => <CourseListing {...props} />}
            />
          {/* 
            <Route
              path="/course-pdf"
              exact
              render={props => <CourseContentPdf {...props} />}
            />




                        
            <Route path="/admin">
              <AdminLayout>
                <Route
                  path="/admin/home"
                  exact
                  render={props => <AdminHome {...props} />}
                />

              </AdminLayout>
            </Route>  */}
          </Switch>
        </Suspense>
        {/* </Elements> */}
        <FooterWeb />
      </div>
    );
  }
}

//@ts-ignore
export default withRouter(WebRoutes);
