import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { BsInstagram, BsFacebook } from 'react-icons/bs';
import { FaTwitter } from 'react-icons/fa';
import { AiOutlineYoutube } from 'react-icons/ai';
import { GOOGLEPLAYIMG } from './assets';
import { APPSTORE } from './assets'
import { RectangleFooter } from './assets'





class FooterWeb extends Component {
  render() {
    return (
      <>
        <Row justify='center' className='footer_main'>

          <Col lg={4} className='footer_col'>
            <div>
              <h4 className='footer_heading'>Contact us</h4>
              <p className='footer_p'>Send a message</p>
              <p className='footer_p_second'>(+44) 1234-567-89</p>
            </div>
          </Col>
          <Col lg={4} className='footer_col'>
            <div>
              <h4 className='footer_heading'>About us</h4>
              <p className='footer_p1'>Customer reviews</p>
              <p className='footer_p1 footer_p2'>FAQs</p>
            </div>
          </Col>
          <Col lg={4} className='footer_col'>
            <div >
              <h4 className='footer_heading'>Social</h4>
              <div className='footer_scial_media_icons'>
                <BsFacebook className='social_media_icons_main' />
                <BsInstagram className='social_media_icons_main' />
                <FaTwitter className='social_media_icons_main' />
                <AiOutlineYoutube className='social_media_icons_main' />
              </div>
            </div>
          </Col>
          <Col lg={4} className='footer_col'>
            <div>
              <h4 className='footer_heading'>Download App</h4>
              <div className='footer_btn'>
                <img src={GOOGLEPLAYIMG} alt="img" className='footer_main_btn' />
                <img src={APPSTORE} alt="img" className='footer_main_btn' />
              </div>
            </div>
          </Col>
        </Row>
        <Row className='footer_down'>
          <Col lg={24}>
            <p className='p_footer'>Copyright © 2021 Studio Store. All rights reserved - Powered by <span className='footer_span' >Builder.ai</span> </p>
          </Col>
        </Row>

      </>
    )
  }
}

export default FooterWeb
