import React, { Component } from 'react'
import { Row, Col } from 'antd'
import "antd/dist/antd.css";
import HeaderNav from '../../studio-store-elearning-components/src/AppWebHeader/HeaderNav.web'
import FooterWeb from '../../studio-store-elearning-components/src/AppWebFooter/Footer.web';
import { HOMEBANNERIMG } from './assets';
import { HOMERECTANGLE } from './assets';
import { Star5, Star } from './assets';




class Home extends Component {

    renderLearningBannerblock = () => {

        return (
            <>
                <Row style={{ margin: "66px 0px 0px" }}>
                    <Col lg={24} md={24} >
                        <div className='banner_first_block'>
                            <img src={HOMEBANNERIMG} className='ProfileBgImg' />
                        </div>
                        <div className='banner_first_block_head_text'>
                            <h3 className='banner_first_block_banner_head'>Andy’s online school <p className='banner_first_block_banner_p'>Awarded best remote learning 2022 by Ofsted</p></h3>

                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={24}>
                        <div className='first_block_text'>
                            <h4 className='first_block_text_main'>
                                Andy’s Online School culture and ethos flows directly from its motto:  <br /> Per Ardua Ad Summa, 'Through Difficulties to the Heights'.
                            </h4>
                            <a href="" className='first_block_text_main_link'>Learn more about us</a>
                        </div>
                    </Col>
                </Row> */}
            </>

        )
    }


    renderFirstBlock = () =>{
        return(
            <>
                 <Row>
                    <Col lg={24}>
                        <div className='first_block_text'>
                            <h4 className='first_block_text_main'>
                                Andy’s Online School culture and ethos flows directly from its motto:  <br /> Per Ardua Ad Summa, 'Through Difficulties to the Heights'.
                            </h4>
                            <a href="" className='first_block_text_main_link'>Learn more about us</a>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    renderContentblock = () => {
        return (
            <div className='content_title_part container'>
                <Row gutter={24} justify="center" className='content_title_part_row'>
                    <Col lg={12} xl={12}>
                        <div className='second_block_left_part'>
                            <img src={HOMERECTANGLE} className='ProfileBgImg' />
                        </div>
                    </Col>
                    <Col lg={12} xl={12}>
                        <div className='second_block_right_part'>
                            <div>
                                <h4 className='  second_block_heading'>
                                    Content title
                                </h4>
                                <p className='second_block_p'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat aenean blandit ut diam in integer ac gravida est. Lectus vulputate id tellus sed faucibus. Elit duis tempus rhoncus, eleifend interdum id sem congue pellentesque. Enim viverra nibh arcu ornare turpis. Bibendum eget amet porttitor et. Etiam dictum malesuada auctor est, commodo semper donec amet
                                </p>
                                <a href="" className='first_block_text_main_link'>Link</a>
                            </div>
                        </div>

                    </Col>
                </Row>

                <Row gutter={24} className='content_title_part_row'>

                    <Col lg={12} xl={12}>
                        <div className='second_block_right_part'>
                            <div>
                                <h4 className='  second_block_heading'>
                                    Content title
                                </h4>
                                <p className='second_block_p' >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat aenean blandit ut diam in integer ac gravida est. Lectus vulputate id tellus sed faucibus. Elit duis tempus rhoncus, eleifend interdum id sem congue pellentesque. Enim viverra nibh arcu ornare turpis. Bibendum eget amet porttitor et. Etiam dictum malesuada auctor est, commodo semper donec amet
                                </p>
                                <a href="" className='first_block_text_main_link'>Link</a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} xl={12}>
                        <div className='second_block_left_part'>
                            <img src={HOMERECTANGLE} className='ProfileBgImg' />
                        </div>
                    </Col>
                </Row>

                <Row gutter={24} className='content_title_part_row'>
                    <Col lg={12} xl={12}>
                        <div className='second_block_left_part'>
                            <img src={HOMERECTANGLE} className='ProfileBgImg' />
                        </div>
                    </Col>
                    <Col lg={12} xl={12}>
                        <div className='second_block_right_part'>
                            <div>
                                <h4 className='second_block_heading'>
                                    Content title
                                </h4>
                                <p className='second_block_p'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat aenean blandit ut diam in integer ac gravida est. Lectus vulputate id tellus sed faucibus. Elit duis tempus rhoncus, eleifend interdum id sem congue pellentesque. Enim viverra nibh arcu ornare turpis. Bibendum eget amet porttitor et. Etiam dictum malesuada auctor est, commodo semper donec amet
                                </p>
                                <a href="" className='first_block_text_main_link'>Link</a>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>

        )
    }


    renderThirdValuesblock = () => {
        return (
            <div className='third_values_block'>
                <h4 className='third_values_block_heading'>OUR CORE VALUES
                    <div className='values_hr_line'>
                        <hr className='values_hr_line_main'></hr>
                    </div>
                </h4>
                <Row justify='center'>
                    <Col lg={5} md={8} sm={24} xs={24}>

                        <div className='third_values_block_main'>
                            <h4 className='third_values_block_main_heading'>1</h4>
                            <div className='third_values_block_main_p_h'>
                                <h5 className='third_values_block_main_inner_heading'>Commitment</h5>
                                <p className='third_values_block_main_inner_p'> We believe that, in order to fulfil our motto, we must exhibit four core values above all others</p>
                            </div>

                        </div>
                    </Col>
                    <Col lg={5} md={8} sm={24} xs={24}>
                        <div className='third_values_block_main'>
                            <h4 className='third_values_block_main_heading'>2</h4>
                            <div className='third_values_block_main_p_h'>
                                <h5 className='third_values_block_main_inner_heading'>Compassion</h5>
                                <p className='third_values_block_main_inner_p'> We believe that, in order to fulfil our motto, we must exhibit four core values above all others</p>
                            </div>
                        </div>

                    </Col>
                    <Col lg={5} md={8} sm={24} xs={24}>
                        <div className='third_values_block_main'>
                            <h4 className='third_values_block_main_heading'>3</h4>
                            <div className='third_values_block_main_p_h'>
                                <h5 className='third_values_block_main_inner_heading'>Courage</h5>
                                <p className='third_values_block_main_inner_p'> We believe that, in order to fulfil our motto, we must exhibit four core values above all others</p>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>

        )
    }


    renderFourthTestimonialblock = () => {
        return (
            <div className='third_values_block  Fourth_Testimonial_block'>
                <h4 className='third_values_block_heading'>TESTIMONIALS
                    <div className='values_hr_line'>
                        <hr className='values_hr_line_main'></hr>
                    </div>
                </h4>

                <Row justify='center'>
                    <Col lg={6}>
                        <div className='third_values_block_main'>
                            <h4 className='third_values_block_main_heading Fourth_testimonials_block_main_heading'>John B
                                <div>
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                </div>
                            </h4>
                            <div className='third_values_block_main_p_h  fourth_testimonial_block_p_h'>
                                <p>"Description here Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='third_values_block_main'>
                            <h4 className='third_values_block_main_heading Fourth_testimonials_block_main_heading'>Lee L
                                <div>
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star5} alt='' />
                                </div>
                            </h4>
                            <div className='third_values_block_main_p_h'>
                                <p>"Description here Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='third_values_block_main'>
                            <h4 className='third_values_block_main_heading  Fourth_testimonials_block_main_heading'>Fabian A
                                <div>
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                    <img src={Star} alt='' />
                                </div>
                            </h4>
                            <div className='third_values_block_main_p_h'>
                                <p>"Description here Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        )
    }
    static instance: any;




    render() {
        return (
            <>
                <Row>
                            {this.renderLearningBannerblock()}
                    <div className='Container'>
                        <div className='Main_Container'>
                            {this.renderFirstBlock()}
                            {this.renderContentblock()}
                            {this.renderThirdValuesblock()}
                        </div>
                        {this.renderFourthTestimonialblock()}
                    </div>
                </Row>
            </>
        )
    }
}

export default Home


