//play store Images
export const GOOGLEPLAYIMG = require('./images/googlePlayImg.png');

export const APPSTORE= require('./images/appStore.jpeg');
export const RectangleFooter= require('./images/RectangleFooter.png');






