import React, { Component } from 'react'
import { Avatar, Layout, Row } from 'antd'
import { HEADERNAVREACT } from './assets'
import './css/HeaderNav.web.css'
import { AiOutlineSearch } from "react-icons/ai";
import { notification } from "antd";
import { NavLink } from "react-router-dom"
const { Header, Content, Sider } = Layout

class HeaderNav extends Component {
  openNotificationWithIcon = () => {
    notification["success"]({
      message: "Profile Updated Successfully",
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Header className='site-layout-sub-header-background' style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <div className='home-logo-head '>
              <h1 className='heading_logo'>
                <NavLink to="/">
                  <div className='header_nav_logo'>
                  <img className='nav_logo_color' src={HEADERNAVREACT} />
                  <h5 className='nav_logo_head'>LOGO</h5>
                  </div>
                </NavLink>
              </h1>
              <h4 className='logo_text'>Builder Fan Club</h4>
            </div>
            <div className='head_right_portion'>
              <div className='input_search_nav'>
                <input className='input_nav' type="text" placeholder='Search' />
                <AiOutlineSearch className='search_icon_nav' />
              </div>
              <div>
                <NavLink to="/login" className='header_right_portion_btn' >Login/SignUp</NavLink>
              </div>
            </div>
          </Header>
        </Row>
      </div>
    )
  }
}

export default HeaderNav